import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { egyptLakeReleaseDate, redEarthCreekReleaseDate, hohnAlbumDate } from './releaseDate-data';

export default [
    {
        title: `Höhn`,
        format: `LP`,
        artist: `Höhn`,
        featuredArtist: null,
        // releaseDate: `2022-11-18T00:00:00`,
        date: hohnAlbumDate,
        streamingLinks: [
            {
                name: 'Spotify',
                link: `http://stns.ffm.to/HOHN`,
            },
            {
                name: 'Apple Music',
                link: `http://stns.ffm.to/HOHN`
            },
            {
                name: 'Bandcamp',
                link: `http://stns.ffm.to/HOHN`
            },
            {
                name: 'SoundCloud',
                link: `http://stns.ffm.to/HOHN`
            },
            {
                name: 'YouTube',
                link: `http://stns.ffm.to/HOHN`
            }
        ],
        coverArt: <StaticImage
        src="../images/music/hohn_album_cover_art_web.jpg"
        quality={75}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="hohn album cover"
        className="shadow-lg"
      />
    },
    {
        title: `Egypt Lake`,
        format: `Single`,
        artist: `Höhn`,
        featuredArtist: null,
        // releaseDate: `2022-10-07T00:00:00`,
        date: egyptLakeReleaseDate,
        streamingLinks: [
            {
                name: 'Spotify',
                link: `http://stns.ffm.to/EgyptLake`,
            },
            {
                name: 'Apple Music',
                link: `http://stns.ffm.to/EgyptLake`
            },
            {
                name: 'Bandcamp',
                link: `http://stns.ffm.to/EgyptLake`
            },
            {
                name: 'SoundCloud',
                link: `http://stns.ffm.to/EgyptLake`
            },
            {
                name: 'YouTube',
                link: `https://youtu.be/mCSP5J52AMY`
            }
        ],
        coverArt: <StaticImage
        src="../images/music/hohn_single_2_cover_art_web.jpg"
        quality={75}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="hohn album cover"
        className="shadow-lg"
      />
    },
    {
        title: `Red Earth Creek`,
        format: `Single`,
        artist: `Höhn`,
        featuredArtist: null,
        // releaseDate: `2022-10-28T00:00:00`,
        date: redEarthCreekReleaseDate,
        streamingLinks: [
            {
                name: 'Spotify',
                link: `http://stns.ffm.to/redearthcreek`,
            },
            {
                name: 'Apple Music',
                link: `http://stns.ffm.to/redearthcreek`
            },
            {
                name: 'Bandcamp',
                link: `http://stns.ffm.to/redearthcreek`
            },
            {
                name: 'SoundCloud',
                link: `http://stns.ffm.to/redearthcreek`
            },
            {
                name: 'YouTube',
                link: `http://stns.ffm.to/redearthcreek`
            }
        ],
        coverArt: <StaticImage
        src="../images/music/hohn_single_1_cover_art_web.jpg"
        quality={75}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="hohn album cover"
        className="shadow-lg"
      />
    }
]